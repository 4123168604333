<template>
  <v-card>
    <v-card-title class="pt-2 pb-2">
      <span style="font-size: 18px;">
        {{ documentType }}
      </span>

      <v-spacer />

      <div
        class="d-flex align-center"
        style="gap: 20px; width: 50%;"
      >
        <v-text-field
          v-model="filterText"
          :prepend-inner-icon="icons.mdiMagnify"
          :label="'Buscar ' + documentType"
          style="top: 14px"
          outlined
          dense
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create',documents[0])"
        >
          <v-icon
            size="25"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>

          <span>
            Novo
          </span>
        </v-btn>

        <v-btn
          color="white"
          outlined
          @click="$emit('close-window')"
        >
          <v-icon
            size="20"
            class="me-2"
          >
            {{ icons.mdiArrowLeft }}
          </v-icon>

          <span>
            Voltar
          </span>
        </v-btn>
      </div>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="filteredDocuments"
      :loading="isLoadingData"
      loading-text="Carregando dados..."
    >
      <template
        v-slot:no-data
      >
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:item.showDocument="{item}">
        <div
          class="d-flex justify-center align-center"
        >
          <v-btn
            icon
            color="warning"
            :loading="isLoadingFile === item.id"
            @click="showFileInBrowser(item.id, item.name)"
          >
            <v-icon>{{ icons.mdiEye }}</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.tools="{item}">
        <div
          class="d-flex justify-center align-center"
          style="gap: 5px;"
        >
          <v-icon
            medium
            color="info"
            @click="openModal('details',item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>

          <v-icon
            medium
            color="error"
            @click="handleDelete(item.id)"
          >
            {{ icons.mdiTrashCan }}
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showModalCreate"
      width="980px"
    >
      <Create
        :process="processes"
        :document-type="documentType"
        :occupations="occupations"
        :companies="companies"
        :companie-info="companieInfo"
        @updatedTable="getDataFromTable()"
        @close="showModalCreate = false"
      />
    </v-dialog>

    <v-dialog
      v-model="showModalDetails"
      width="980px"
    >
      <Details
        :key="document.id"
        :process="processes"
        :raw-document="document"
        :occupations="occupations"
        :companies="companies"
        @updatedTable="getDataFromTable()"
        @close="showModalDetails = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiArrowLeft, mdiEye, mdiMagnify, mdiPencil, mdiPlaylistPlus, mdiTrashCan,
} from '@mdi/js'

import axiosIns from '@/plugins/axios'
import { saveAs } from 'file-saver'
import Create from './Create.vue'
import Details from './Details.vue'

export default {
  components: {
    Create,
    Details,
  },

  mixins: [formatters, messages],

  props: {
    documents: {
      type: Array,
      default: () => ([]),
      required: true,
    },

    documentType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      document: { id: '' },

      companieInfo: {},
      filterText: '',
      filteredDocuments: [],

      processes: [],
      companies: [],
      occupations: [],
      localDocuments: [],

      endpointToDelete: 'api/v1/sgq/registration/destroy',

      listDocuments: [],
      headers: [
        {
          text: 'IDENTIFICAÇÃO',
          value: 'code',
          sortable: false,
          align: 'left',
        },
        {
          text: 'NOME',
          value: 'name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'PROCESSO',
          value: 'process.description',
          sortable: false,
          align: 'left',
        },
        {
          text: 'EMPRESA',
          value: 'company.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'VISUALIZAR DOCUMENTO',
          value: 'showDocument',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FERRAMENTAS',
          value: 'tools',
          sortable: false,
          align: 'center',
        },
      ],

      isLoadingData: false,
      isLoadingFile: false,

      showModal: false,
      showModalCreate: false,
      showModalDetails: false,

      icons: {
        mdiMagnify,
        mdiPlaylistPlus,
        mdiEye,
        mdiTrashCan,
        mdiArrowLeft,
        mdiPencil,
      },
    }
  },

  watch: {
    filterText() {
      this.filterData()
    },
  },

  created() {
    this.getProcesses()
    this.getOccupations()
    this.getCompanies()
    this.getDocuments()
    this.getCompanieDetails()

    this.localDocuments = [...this.listDocuments]
    this.filteredDocuments = [...this.listDocuments]
    window.addEventListener('updatedTable', this.getDataFromTable)
  },

  beforeDestroy() {
    window.removeEventListener('updatedTable', this.getDataFromTable)
  },

  methods: {
    async getDocuments() {
      const { id } = this.$route.params

      const response = await axiosIns.post('/api/v1/sgq/registration/find_card_record', { type: this.documentType, companies_id: id })

      this.companieInfo.id = response.data.data[0].company.id
      this.companieInfo.name = response.data.data[0].company.name

      this.listDocuments = response.data.data
      this.filteredDocuments = this.listDocuments
    },

    async getProcesses() {
      try {
        const response = await axiosIns.get('api/v1/sgq/process/index')
        this.processes = response.data.data
      } catch (error) {
        console.error(error)
      }
    },

    async getOccupations() {
      try {
        const response = await axiosIns.get('api/v1/records/occupation/index')

        this.occupations = response.data.data.map(occupation => ({
          ...occupation,
          id: occupation.id,
          name: `${occupation.occupation.name} - ${occupation.sector.name}`,
        }))
      } catch (error) {
        console.error(error)
      }
    },

    async getCompanies() {
      try {
        const response = await axiosIns.get('api/v1/sgq/company/index')
        this.companies = response.data.data
      } catch (error) {
        console.error(error)
      }
    },

    async getCompanieDetails() {
      const { id } = this.$route.params

      try {
        const response = await axiosIns.get(`api/v1/sgq/company/edit/${id}`)

        this.companieInfo.id = response.data.data.id
        this.companieInfo.name = response.data.data.name
      } catch (error) {
        console.error(error)
      }
    },

    async getDataFromTable() {
      this.isLoadingData = true

      const { id } = this.$route.params

      try {
        const response = await axiosIns.post('/api/v1/sgq/registration/find_card_record', { type: this.documentType, companies_id: id })
        this.localDocuments = this.formatData(response.data.data)
        this.filteredDocuments = this.formatData(response.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoadingData = false
      }
    },

    formatData(data) {
      return data.map(document => ({
        ...document,
        identify: `${document.type}-${document.code}`,
      }))
    },

    filterData() {
      this.filteredDocuments = this.listDocuments.filter(document => {
        const textFilter = this.filterText.toUpperCase()

        const {
          name,
          code,
          company,
          process,
          identify,
        } = document

        return (name?.includes(textFilter)
          || company?.name.includes(textFilter)
          || process?.description.includes(textFilter)
          || identify?.includes(textFilter))
          || code?.includes(textFilter)
      })
    },

    async showFileInBrowser(id, name) {
      this.isLoadingFile = id

      const body = {
        id,
      }

      await axiosIns
        .post('/api/v1/sgq/registration/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const fileType = contentType.split('/')[1]
          const { data: responseData } = response

          if (fileType === 'pdf') {
            const file = this.createFile({
              data: responseData,
              contentType,
              fileType,
            })
            const urlFromFile = URL.createObjectURL(file)
            window.open(urlFromFile, '__blank')

            return
          }

          const file = this.createFile({
            name,
            data: responseData,
            contentType,
            fileType,
          })
          this.downloadFile(file)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoadingFile = ''
        })
    },

    createFile({
      name,
      data,
      contentType,
      fileType,
    }) {
      return new File([data], `${name}.${fileType}`, {
        type: contentType,
      })
    },

    downloadFile(file) {
      saveAs(file)
    },

    async handleDelete(id) {
      await this.deleteRegister(this.endpointToDelete, id)

      await this.getDataFromTable()
    },

    updateTableData() {
      this.$emit('updatedTable', { type: this.documentType })
    },

    openModalDetails(document) {
      this.document = document
      this.showModalDetails = true
    },

    openModal(type, document) {
      if (type === 'create') {
        this.showModalCreate = true

        return
      }

      this.document = document
      this.showModalDetails = true
    },
  },
}
</script>
